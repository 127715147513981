html,
body {
    background-color: #f3f3f3;
    font-family: 'Roboto', sans-serif;
    padding: 0;
    margin: 0;
    overflow-x: hidden;
}

.body {
    min-height: 92vh;
}

.search-image {
    position: relative;
    height: 10vh;
}

.search-image::before {
    content: '';
    /*background-image: url('img/Hodges_easter-island.jpeg');*/
    background-image: url('img/bg.png');
    /* background-color: black; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    filter: brightness(0.25);
    top: 0;
    left: 0;
    height: 28vh;
    width: 100vw;
}

.search-title {
    position: relative;
    height: 16vh;
}

.hero-image {
    position: relative;
    height: 100vh;
}

.hero-image::before {
    content: '';
    /*background-image: url('img/Hodges_easter-island.jpeg');*/
    background-image: url('img/bg.png');
    /* background-color: black; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: absolute;
    filter: brightness(0.25);
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.hero-title {
    position: relative;
    height: 100vh;
}

.footer {
    background-color: white;
    position: relative;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 8vh;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.08);
}

.first-anchor {
    position: relative;
    top: -42px;
}

.intro-image {
    margin-top: auto;
    margin-bottom: auto;
    width: 4.5vw;
    max-width: 24px;
    height: auto;
}

.paragraph-image {
    margin-top: auto;
    margin-bottom: auto;
    width: 5vw;
    max-width: 26px;
    height: auto;
}

.home-content {
    position: relative;
    z-index: 1;
}

.home-card {
    transition: color 300ms;
    transition: background-color 300ms;
}

.home-card:hover img {
    filter: brightness(0) invert(1);
}